<template>
  <v-row class="justify-center">
    <v-col md="8">
      <v-card tile class="pa-5 d-flex align-center justify-space-around text-center amber">
        <h1>Jump Into Course Creation</h1>
        <v-btn x-large class="ma-2" color="secondary" :to="{ name: 'CreateCourse' }">
          Manage courses
        </v-btn>
        <v-btn x-large class="ma-2" color="secondary" :to="{ name: 'ManageRoutines' }">
          Manage Routines
        </v-btn>
      </v-card>

      <sms-dashboard></sms-dashboard>

      <v-card tile class="pa-5 mt-2 d-flex align-center primary">
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'Categories' }">
          Manage Categories
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'Tags' }">
          Manage Tags
        </v-btn>
      </v-card>

      <v-card tile class="pa-5 mt-2 d-flex align-center primary">
        <v-btn x-large class="ma-2" color="light" :to="{name: 'Users'}">
          Users
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'Payments' }">
          Payments
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{name: 'AddToCourse'}">
          Add to course
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'ManageAdvertisements' }">
          Advertisements
        </v-btn>
      </v-card>

      <v-card v-if="bookFeature" tile class="pa-5 mt-2 d-flex align-center primary">
        <v-btn x-large class="ma-2" color="light" :to="{name: 'InsertBook'}">
          Books
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{name: 'BookOrders'}">
          Book Orders
        </v-btn>
      </v-card>

      <v-card tile class="pa-5 mt-2 d-flex align-center primary">
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'McqStore' }">
          MCQ Store
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'SearchMcq' }">
          Search MCQ
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{name: 'WrittenExamList'}">
          Written Exam List
        </v-btn>
      </v-card>

      <v-card tile class="pa-5 mt-2 d-flex flex-wrap align-center primary">
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'Notices' }">
          Notices
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'Notification' }">
          Notification
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'Popup' }">
          Popup
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{name: 'VarsityCategories'}">
          Varsity Info
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{name: 'ManageFormFillUp'}">
          Form fill-up
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{name: 'Subjects'}">
          Subject Review
        </v-btn>
      </v-card>

      <v-card tile class="pa-5 mt-2 d-flex align-center primary">
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'FormFillUpRequests' }">
          Form Fill Up
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{name: 'SmsAlertRequests'}">
          SMS Alert Requests
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'SendSmsAlert' }">
          Send SMS Alert
        </v-btn>
      </v-card>

      <v-card tile class="pa-5 mt-2 d-flex align-center primary">
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'Settings' }">
          Settings
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'ManageUserGuides' }">
          User Guide
        </v-btn>
      </v-card>
      <v-card tile class="pa-5 mt-2 d-flex flex-wrap align-center primary">
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'HomeContent' }">
          Home Content
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'Hr' }">
          HR
        </v-btn>
<!--        <v-btn x-large class="ma-2" color="light" :to="{ name: 'PreviewTeachers' }">
          Teachers
        </v-btn>
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'PreviewFounders' }">
          Founders
        </v-btn>-->
        <v-btn x-large class="ma-2" color="light" :to="{ name: 'PreviewReviews' }">
          Reviews
        </v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import SmsDashboard from "./SmsDashboard";

export default {
  components: {SmsDashboard},
  data() {
    return {
      bookFeature: process.env.VUE_APP_BOOK_SHOP === 'true'
    }
  }
}
</script>
